import { COUNTRY_CODES } from './static-data/country-codes';
import { ADDRESS_COUNTRIES_TAXABLE_EU, ADDRESS_COUNTRIES_WITH_STATE, ADDRESS_FIELD_KEYS } from 'later/utils/constants';

import type IntlService from 'ember-intl/services/intl';
import type { Maybe } from 'shared/types';

/**
 * Flattens address from saved address and converts to camel case
 *
 * @param response the most recently saved address for a user
 *
 * @returns containing AddressFields. Field will be set to empty string if it doesn't exist.
 */
export const assembleAddress = (response: any): AddressFields => ({
  //
  [ADDRESS_FIELD_KEYS.CITY]: response?.city || '',
  [ADDRESS_FIELD_KEYS.COUNTRY]: response?.country || '',
  [ADDRESS_FIELD_KEYS.LINE_ONE]: response?.line1 || '',
  [ADDRESS_FIELD_KEYS.LINE_TWO]: response?.line2 || '',
  [ADDRESS_FIELD_KEYS.STATE]: response?.state || '',
  [ADDRESS_FIELD_KEYS.POSTAL_CODE]: response?.postal_code || '',
  [ADDRESS_FIELD_KEYS.VERIFIED]: response?.verified || false
});

export interface AddressFields {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  state?: string;
  postalCode?: string;
  verified?: boolean;
}

export type SuggestedAddressFields = Omit<AddressFields, 'verified'>;

export interface SuggestedChangeItem {
  key: string;
  suggested: string;
}

interface AddressConfigField {
  key: keyof AddressFields;
  isRequired: boolean;
  label: string;
}
export interface AddressConfig {
  fields: AddressConfigField[];
  shouldDisplayTaxAmount: boolean;
  formatAddress: (address: AddressFields) => string;
}

/**
 * Address form config object based on country code.
 * Will render form fields based on the order and the properties
 * in the fields array. Country is included in the config as the fields are used
 * in the address service but its form display is not based on the config values
 *
 */
export const ADDRESS_FORM_CONFIG = (
  countryCode: Maybe<string>,
  intl: IntlService,
  rolloutEuVat: boolean
): AddressConfig => {
  const EU_COUNTRIES_WITH_SPECIAL_ADDRESS_CONFIG = [COUNTRY_CODES.IRELAND, COUNTRY_CODES.ITALY, COUNTRY_CODES.MALTA];
  const filteredEuCountries = ADDRESS_COUNTRIES_TAXABLE_EU.filter(
    (country) => !EU_COUNTRIES_WITH_SPECIAL_ADDRESS_CONFIG.includes(country)
  );

  if (countryCode === COUNTRY_CODES.UNITED_KINGDOM) {
    return {
      fields: [
        { key: 'country', isRequired: true, label: '' },
        { key: 'line1', isRequired: true, label: intl.t('account.subscription.billing.address.address_1') },
        { key: 'line2', isRequired: false, label: intl.t('account.subscription.billing.address.address_2') },
        { key: 'city', isRequired: true, label: intl.t('account.subscription.billing.address.town') },
        { key: 'postalCode', isRequired: true, label: intl.t('account.subscription.billing.address.postcode') }
      ],
      shouldDisplayTaxAmount: true,
      formatAddress: (address: AddressFields): string => {
        return `${address.line2 || ''} ${address.line1}, ${address.postalCode}, ${address.city}`;
      }
    } as const;
  } else if (countryCode === COUNTRY_CODES.IRELAND && rolloutEuVat) {
    return {
      fields: [
        { key: 'country', isRequired: true, label: '' },
        { key: 'line1', isRequired: true, label: intl.t('account.subscription.billing.address.address_1') },
        { key: 'line2', isRequired: false, label: intl.t('account.subscription.billing.address.address_2') },
        { key: 'city', isRequired: true, label: intl.t('account.subscription.billing.address.town') },
        { key: 'state', isRequired: true, label: intl.t('account.subscription.billing.address.county') },
        { key: 'postalCode', isRequired: true, label: intl.t('account.subscription.billing.address.eircode') }
      ],
      shouldDisplayTaxAmount: true,
      formatAddress: (address: AddressFields): string => {
        return `${address.line2 || ''} ${address.line1}, ${address.city}, ${address.state} ${address.postalCode}`;
      }
    } as const;
  } else if (countryCode === COUNTRY_CODES.ITALY && rolloutEuVat) {
    return {
      fields: [
        { key: 'country', isRequired: true, label: '' },
        { key: 'line1', isRequired: true, label: intl.t('account.subscription.billing.address.address_1') },
        { key: 'line2', isRequired: false, label: intl.t('account.subscription.billing.address.address_2') },
        { key: 'postalCode', isRequired: true, label: intl.t('account.subscription.billing.address.postal_code') },
        { key: 'city', isRequired: true, label: intl.t('account.subscription.billing.address.city') },
        { key: 'state', isRequired: true, label: intl.t('account.subscription.billing.address.province') }
      ],

      shouldDisplayTaxAmount: true,
      formatAddress: (address: AddressFields): string => {
        return `${address.line2 || ''} ${address.line1}, ${address.postalCode} ${address.city} ${address.state} `;
      }
    } as const;
  } else if (countryCode === COUNTRY_CODES.MALTA && rolloutEuVat) {
    return {
      fields: [
        { key: 'country', isRequired: true, label: '' },
        { key: 'line1', isRequired: true, label: intl.t('account.subscription.billing.address.address_1') },
        { key: 'line2', isRequired: false, label: intl.t('account.subscription.billing.address.address_2') },
        { key: 'city', isRequired: true, label: intl.t('account.subscription.billing.address.city') },
        { key: 'postalCode', isRequired: true, label: intl.t('account.subscription.billing.address.postal_code') }
      ],

      shouldDisplayTaxAmount: true,
      formatAddress: (address: AddressFields): string => {
        return `${address.line2 || ''} ${address.line1},  ${address.city} ${address.postalCode} ${address.state} `;
      }
    } as const;
  } else if (filteredEuCountries.some((code) => code === countryCode) && rolloutEuVat) {
    return {
      fields: [
        { key: 'country', isRequired: true, label: '' },
        { key: 'line1', isRequired: true, label: intl.t('account.subscription.billing.address.address_1') },
        { key: 'line2', isRequired: false, label: intl.t('account.subscription.billing.address.address_2') },
        { key: 'postalCode', isRequired: true, label: intl.t('account.subscription.billing.address.postal_code') },
        { key: 'city', isRequired: true, label: intl.t('account.subscription.billing.address.city') }
      ],
      shouldDisplayTaxAmount: true,
      formatAddress: (address: AddressFields): string => {
        const line2 = address.line2 ? ` ${address.line2}` : '';
        return `${address.line1}${line2}, ${address.postalCode} ${address.city}`;
      }
    } as const;
  }

  const stateKey = ADDRESS_COUNTRIES_WITH_STATE.includes(countryCode ?? '')
    ? 'account.subscription.billing.address.state'
    : 'account.subscription.billing.address.province';

  const postalCodeKey =
    countryCode === 'US'
      ? 'account.subscription.billing.address.zip_code'
      : 'account.subscription.billing.address.postal_code';

  return {
    fields: [
      { key: 'country', isRequired: true, label: '' },
      { key: 'line1', isRequired: true, label: intl.t('account.subscription.billing.address.address_1') },
      { key: 'line2', isRequired: false, label: intl.t('account.subscription.billing.address.address_2') },
      { key: 'city', isRequired: true, label: intl.t('account.subscription.billing.address.city') },
      { key: 'state', isRequired: true, label: intl.t(stateKey) },
      { key: 'postalCode', isRequired: true, label: intl.t(postalCodeKey) }
    ],

    shouldDisplayTaxAmount: false,
    formatAddress: (address: AddressFields): string => {
      return `${address.line2 || ''} ${address.line1}, ${address.city}, ${address.state} ${address.postalCode}`;
    }
  } as const;
};

export type Field = {
  FEKey: keyof AddressFields;
  APIKey: 'locality' | 'country' | 'address1' | 'administrative_area' | 'postal_code' | 'thoroughfare' | 'premise';
  APISuggestionKey?: 'postal_code_short';
};

// Interface structure defined by
// https://www.smarty.com/docs/cloud/international-street-api
export interface SmartyResponse {
  address1: string;
  address2: string;
  address3: string;
  components: SmartyComponent;
  metadata: Record<string, string>;
  analysis: {
    verification_status: string;
    address_precision: string;
    max_address_precision: string;
    changes: { components: SmartyComponent };
  };
}

interface SmartyComponent {
  administrative_area: string;
  country_iso_3: string;
  locality: string;
  postal_code: string;
  postal_code_short: string;
  premise: string;
  premise_number: string;
  thoroughfare: string;
  thoroughfare_name: string;
  thoroughfare_trailing_type: string;
  address1?: string;
  country?: string;
  post_box?: string;
  post_box_type?: string;
}
