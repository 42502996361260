import { isArray } from '@ember/array';
import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { isNull, isEmpty as _isEmpty, isEqual } from 'lodash';

import { TRIAL_EVENTS, TRIAL_TYPES } from 'later/utils/constants';
import { fetch } from 'later/utils/fetch';
import promiseHash from 'later/utils/promise-hash';
import { timestamp } from 'later/utils/time-format';
import Discount from 'settings/utils/discount';

import type AlertsService from './alerts';
import type ArrayProxy from '@ember/array/proxy';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type SubscriptionModel from 'later/models/subscription';
import type SubscriptionPlanModel from 'later/models/subscription-plan';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type SegmentService from 'later/services/segment';
import type SegmentEventsService from 'later/services/segment-events';
import type { Maybe, ValueOfType } from 'shared/types';
import type { PlanType } from 'shared/types/plans';
import type { SubscriptionPlatform } from 'shared/types/subscriptions';
import type { JsonObject } from 'type-fest';

interface SubscriptionKeyValues {
  name: string;
  amount: number;
  frequency: string;
  additionalSocialSets: number;
  additionalUsers: number;
  planModifiedTime: number;
  socialSetsModifiedTime: number;
  usersModifiedTime: number;
}

export default class SubscriptionsService extends Service.extend(Evented) {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare segment: SegmentService;
  @service declare segmentEvents: SegmentEventsService;
  @service declare store: Store;

  accountUpdatePromise?: {
    count: number;
    expectedCount: number;
    resolve: () => void;
  };

  @tracked discount: Maybe<Discount> = null;
  @tracked subscriptionPlans: SubscriptionPlanModel[] = [];
  /**
   * Subscription
   * Used to manage stripe subscriptions in checkout and billing
   * can be undefined for iOS subscribers, so it should not be used
   * to determine feature access
   */
  @tracked subscription: SubscriptionModel | Record<string, never> = {};
  @tracked latestSubscription: SubscriptionModel | Record<string, never> = {};
  @tracked subscriptionPlan: SubscriptionPlanModel | Record<string, never> = {};
  @tracked upcomingSubscriptionPlan: SubscriptionPlanModel | Record<string, never> = {};
  @tracked showMobileRedirectModal = false;
  @tracked currentMobileModalFeatureName: Maybe<string> = null;

  originalPlanName: Maybe<string>;

  postLimitUpgradeMap: Record<string, string> = {
    free: 'starter',
    creator: 'starter',
    basics: 'growth',
    starter: 'growth',
    growth: 'advanced'
  };

  planUpgradeOrder: PlanType[] = ['free', 'creator', 'starter', 'basics', 'growth', 'advanced'];

  get cardlessTrialCampaignCompleted(): boolean {
    const trialEvent = this.isLatestSubscriptionAutoTrial
      ? TRIAL_EVENTS.autoTrialEnded
      : TRIAL_EVENTS.sourcelessTrialEnded;

    return (
      this.latestSubscriptionIsCardlessTrial &&
      Boolean(
        this.auth.discoveryListTasks?.find(
          ({ identifier }) => identifier === `${trialEvent}-${this.latestSubscription?.trialEpoch}`
        )
      )
    );
  }

  get accountId(): Maybe<string> {
    return this.currentAccount?.id;
  }

  get canUpgrade(): boolean {
    const topTierPlans: [PlanType, PlanType] = ['brand', 'advanced'];

    return Boolean(this.planType && !topTierPlans.includes(this.planType));
  }

  get businessModel(): Maybe<string> {
    return this.currentAccount?.mainProfile?.businessModel;
  }

  get currentAccount(): Maybe<AccountModel> {
    return this.auth.currentAccount;
  }

  get enterpriseContactUrl(): string {
    return 'https://help.later.com/hc/en-us/requests/new?&tf_14661951125015=source_product&tf_14233517033111=form_reason_enterprise_question';
  }

  get groupId(): Maybe<string> {
    return this.auth.currentGroup?.id;
  }

  get groupSlug(): Maybe<string> {
    return this.auth.currentGroup?.slug;
  }

  get hasActiveTrial(): boolean {
    return Boolean(this.currentAccount?.hasActiveTrial);
  }

  get hasCurrentSubscription(): boolean {
    return !_isEmpty(this.subscription);
  }

  get hasUpcomingDowngrade(): boolean {
    const currentPlanName = this.planName;
    const upcomingPlanName = this.upcomingSubscriptionPlan?.name;
    return Boolean(currentPlanName && upcomingPlanName && currentPlanName !== upcomingPlanName);
  }

  get hasUpcomingIntervalDowngrade(): boolean {
    const currentInterval = this.subscriptionPlan?.interval;
    const upcomingInterval = this.upcomingSubscriptionPlan?.interval;
    return Boolean(currentInterval && upcomingInterval && currentInterval !== upcomingInterval);
  }

  get isLatestSubscriptionAutoTrial(): boolean {
    return this.latestSubscription?.trialType === TRIAL_TYPES.autoTrial;
  }

  get isLatestSubscriptionSourcelessTrial(): boolean {
    return this.latestSubscription?.trialType === TRIAL_TYPES.sourcelessTrial;
  }

  get latestSubscriptionIsCardlessTrial(): boolean {
    return this.isLatestSubscriptionAutoTrial || this.isLatestSubscriptionSourcelessTrial;
  }

  get industry(): Maybe<string> {
    return this.currentAccount?.mainProfile?.industry;
  }

  get isEligibleForTrial(): boolean {
    return Boolean(this.currentAccount?.canTrialPlan);
  }

  get isPaid(): boolean {
    return Boolean(this.subscriptionPlan?.isPaid);
  }

  get isPastDue(): boolean {
    return Boolean(this.subscription?.isPastDue && this.currentAccount?.rolloutPastDueModal);
  }

  get isOnAgencyPlan(): boolean {
    return Boolean(this.subscriptionPlan?.isAgencyPlan);
  }

  /**
   * card-less trials means that the trial was either a sourceless trial or an auto trial
   */
  get isOnCardlessTrial(): boolean {
    return (
      this.hasActiveTrial &&
      (this.subscription?.trialType === TRIAL_TYPES.autoTrial ||
        this.subscription?.trialType === TRIAL_TYPES.sourcelessTrial) &&
      !this.cardlessTrialCampaignCompleted
    );
  }

  get isOnAutoTrial(): boolean {
    return this.isOnCardlessTrial && this.subscription?.trialType === TRIAL_TYPES.autoTrial;
  }

  get isMobileSubscription(): boolean {
    return this.currentAccount?.subscriptionProviderName === 'Apple';
  }

  get isEnterpriseSubscription(): boolean {
    return this.currentAccount?.enterpriseStatus === 'active';
  }

  get numberOfAllowedLinkinbioPages(): number {
    return this.currentAccount?.totalSocialSets || 1;
  }

  get subscriptionCreatedAt(): Maybe<number> {
    return this.currentAccount?.subscriptionCreatedTime;
  }

  get planName(): Maybe<string> {
    return this.subscriptionPlan?.name;
  }

  get planType(): Maybe<PlanType> {
    return this.subscriptionPlan?.planType;
  }

  get platform(): SubscriptionPlatform {
    return this.currentAccount?.subscriptionProviderName === 'Apple' ? 'iOS' : 'Web';
  }

  get upgradeablePlans(): PlanType[] {
    if (!this.canUpgrade) {
      return [];
    }

    const upgradeablePlans = [...this.planUpgradeOrder];
    if (!this.planType) {
      return upgradeablePlans;
    }

    const currentPlanIndex = upgradeablePlans.indexOf(this.planType);
    if (-1 < currentPlanIndex && currentPlanIndex < upgradeablePlans.length - 1) {
      return upgradeablePlans.splice(currentPlanIndex + 1, upgradeablePlans.length - 1);
    }

    return [];
  }

  /**
   * Returns Stripe Plan ID for a given plan type and interval.
   */
  async getSubscriptionPlanStripeId(planType: PlanType, isMonthly = true): Promise<string | undefined> {
    await this.getAllSubscriptionPlans();

    return this.subscriptionPlans.find((p) => p.planType === planType && p.isMonthly === isMonthly)?.stripePlanId;
  }

  /**
   * Reloads the Account's current Subscription, current Subscription Plan, latest Subscription (which can be expired)
   * and upcoming Subscription Plan and sets them to properties on this service.
   */
  async reload(): Promise<{
    subscriptionPlan: Maybe<SubscriptionPlanModel>;
    subscription: Maybe<SubscriptionModel>;
    latestSubscription: Maybe<SubscriptionModel>;
    upcomingSubscriptionPlan: Maybe<SubscriptionPlanModel>;
  }> {
    const keys = ['subscription', 'subscriptionPlan', 'latestSubscription', 'upcomingSubscriptionPlan'];
    keys.forEach((key) => {
      this.cache.remove(key);
    });

    const { subscription, subscriptionPlan, latestSubscription } = await promiseHash({
      subscriptionPlan: this.getSubscriptionPlan('current'),
      subscription: this.getSubscription('current'),
      latestSubscription: this.getSubscription('latest')
    });
    const upcomingSubscriptionPlan = this._getUpcomingSubscriptionPlan();

    return promiseHash({ subscription, subscriptionPlan, latestSubscription, upcomingSubscriptionPlan });
  }

  async getSubscription(type: string): Promise<SubscriptionModel | Record<string, never>> {
    const key = 'subscription';
    if (type === 'current' || type === 'latest') {
      const cachedSubscription = this.cache.retrieve(key);
      if (cachedSubscription) {
        this.subscription = cachedSubscription as unknown as SubscriptionModel;
        return this.subscription;
      }
    }

    try {
      const previousValues = this.#getSubscriptionKeyValues(this.subscription);
      const subscriptions = await this.store.query('subscription', { filter: { type } });
      const subscription = isArray(subscriptions) && subscriptions.firstObject ? subscriptions.firstObject : {};

      if (type === 'current' || type === 'latest') {
        this.cache.add(key, subscription as unknown as JsonObject, { expiry: this.cache.expiry(1, 'days') });

        if (type === 'current') {
          this.subscription = subscription;
        } else {
          this.latestSubscription = subscription;
        }

        const newValues = this.#getSubscriptionKeyValues(this.subscription);
        if (!isEqual(previousValues, newValues)) {
          this.trigger('update:subscription', previousValues, newValues);
        }
      }

      return subscription;
    } catch (error) {
      this.errors.log(error);
      throw error;
    }
  }

  async getSubscriptionDiscount(): Promise<void> {
    if (this.subscription?.id) {
      const response = await fetch(`/api/v2/subscriptions/${this.subscription.id}/discount`);
      this.discount = new Discount(response);
    } else {
      // For free accounts with no subscription id, return an object with same structure but null properties
      this.discount = new Discount({
        discount: null,
        future_amount_including_tax: 0,
        total_discount_amounts: [],
        total_tax_amounts: []
      });
    }
  }

  async getSubscriptionPlan(type: string): Promise<SubscriptionPlanModel | Record<string, never>> {
    const key = 'subscriptionPlan';
    if (type === 'current') {
      const cachedSubscriptionPlan = this.cache.retrieve(key);
      if (cachedSubscriptionPlan) {
        this.subscriptionPlan = cachedSubscriptionPlan as unknown as SubscriptionPlanModel;
        return this.subscriptionPlan;
      }
    }

    try {
      const subscriptionPlan = await this.store.queryRecord('subscription-plan', { filter: { type } });
      if (type === 'current') {
        this.cache.add(key, subscriptionPlan as unknown as JsonObject, { expiry: this.cache.expiry(1, 'days') });
        this.subscriptionPlan = subscriptionPlan;
      }

      return subscriptionPlan;
    } catch (error) {
      this.errors.log(error);
      throw error;
    }
  }

  async getSubscriptionPlans(plan: string, version = 2): Promise<ArrayProxy<SubscriptionPlanModel>> {
    const pricing_version = `v${version}`;

    const subscriptionPlans = await this.store.query('subscription-plan', { plan, pricing_version });
    subscriptionPlans.forEach((subscriptionPlan) => {
      let wasFound = false;

      this.subscriptionPlans.forEach((cachedSubscriptionPlan, idx) => {
        if (cachedSubscriptionPlan.id === subscriptionPlan.id) {
          wasFound = true;
          this.subscriptionPlans.replace(idx, 1, [subscriptionPlan]);
        }
      });

      if (!wasFound) {
        this.subscriptionPlans.pushObject(subscriptionPlan);
      }
    });

    return subscriptionPlans;
  }

  /**
   *  needs to be invalidated to ensure we don't show an old rollout if a user has
   * upgrade to a new rollout. This will cause getAllSubscriptionPlans() to to load a fresh
   * list when needed next
   */
  removeSubscriptionPlansCache(): void {
    this.cache.remove('subscription-plans-loaded');
  }

  async getAllSubscriptionPlans(version = 2): Promise<SubscriptionPlanModel[]> {
    const pricing_version = `v${version}`;
    const cacheKey = 'subscription-plans-loaded';

    if (this.cache.retrieve(cacheKey)) {
      return this.subscriptionPlans;
    }

    const allSubscriptionPlans = (await this.store.query('subscription-plan', { pricing_version })).toArray();

    this.cache.add(cacheKey, true, { expiry: this.cache.expiry(1, 'days') });

    this.subscriptionPlans = allSubscriptionPlans;

    return this.subscriptionPlans;
  }

  async markEndOfCardlessTrial(trialType: ValueOfType<typeof TRIAL_TYPES> | undefined): Promise<void> {
    if (!this.latestSubscription?.trialEpoch) {
      throw new Error('No latest subscription trial epoch found');
    }

    const trialEvent =
      trialType === TRIAL_TYPES.autoTrial ? TRIAL_EVENTS.autoTrialEnded : TRIAL_EVENTS.sourcelessTrialEnded;

    try {
      const endOfAutoTrialDLT = this.store.createRecord('discovery-list-task', {
        identifier: `${trialEvent}-${this.latestSubscription.trialEpoch}`,
        completedTime: timestamp(),
        user: this.auth.currentUserModel
      });

      await endOfAutoTrialDLT.save();
    } catch (error) {
      this.errors.log('Error marking end of auto trial', error);
    }
  }

  async _getUpcomingSubscriptionPlan(): Promise<SubscriptionPlanModel | Record<string, never>> {
    const key = 'upcomingSubscriptionPlan';
    const cachedUpcomingSubscriptionPlan = this.cache.retrieve(key);
    if (cachedUpcomingSubscriptionPlan) {
      this.upcomingSubscriptionPlan = cachedUpcomingSubscriptionPlan as unknown as SubscriptionPlanModel;
      return this.upcomingSubscriptionPlan;
    }

    try {
      let upcomingSubscriptionPlan = {};
      const stripePlanId = this.subscription?.upcomingPlanChanges?.planId;

      if (stripePlanId) {
        const planData = stripePlanId.split('_');
        const planName = planData[0];
        const interval = planData[1];
        const upcomingSubscriptionPlans = await this.getSubscriptionPlans(planName);
        if (upcomingSubscriptionPlans) {
          upcomingSubscriptionPlan =
            upcomingSubscriptionPlans.find(
              (plan) => plan.planType === planName && plan.normalizedInterval === interval
            ) ?? {};
        }
      }

      this.cache.add(key, upcomingSubscriptionPlan, { expiry: this.cache.expiry(1, 'days') });

      this.upcomingSubscriptionPlan = upcomingSubscriptionPlan as unknown as SubscriptionPlanModel;

      return upcomingSubscriptionPlan;
    } catch (error) {
      this.errors.log(error);
      throw error;
    }
  }

  waitForAccountUpdate(expectedCount = 1, timeout = 6000): Promise<void> {
    return new Promise((resolve) => {
      this.accountUpdatePromise = {
        count: 0,
        expectedCount,
        resolve: () => {
          resolve();
          this.accountUpdatePromise = undefined;
        }
      };

      setTimeout(() => {
        this.accountUpdatePromise?.resolve();
      }, timeout);
    });
  }

  receivedAccountUpdate(): void {
    if (!this.accountUpdatePromise?.resolve) {
      return;
    }

    this.accountUpdatePromise.count++;

    if (this.accountUpdatePromise.count >= this.accountUpdatePromise.expectedCount) {
      this.accountUpdatePromise?.resolve();
    }
  }

  openMobileRedirectModal(featureName?: string): void {
    this.currentMobileModalFeatureName = featureName;
    this.segment.track('viewed-page', { page: 'redirect to mobile modal' });
    this.showMobileRedirectModal = true;
  }

  closeMobileRedirectModal(): void {
    this.showMobileRedirectModal = false;
  }

  /**
   * @param location where the upgrade button or link was in the app
   */
  trackUpgrade(location?: string): void {
    const slug = this.groupSlug;
    const id = this.groupId;
    const pathnameSections = window.location.pathname.split('/').filter((item) => item);

    if (pathnameSections[0] === slug) {
      pathnameSections.shift();
    }

    if (pathnameSections[2] == id) {
      pathnameSections.splice(2, 1);
    }

    const pathname = `/${pathnameSections.join('/')}`;

    if (this.isEligibleForTrial) {
      const payload = {
        area: 'upgrade_button',
        location: location ?? null,
        profile: this.auth.currentSocialProfile?.id,
        industry: this.industry ?? null,
        bus_model: this.businessModel ?? null,
        plan_name: null,
        client_id: null
      };
      this.segmentEvents.trackWithGAIntegration('opted-in-for-trial', payload);
    } else if (!this.auth.currentUserModel.isAccountOwner) {
      this.segment.track('clicked-upgrade-button-non-account-owner', {
        source_page: pathname,
        button_location: location ?? null
      });
    } else {
      this.segment.track('clicked-upgrade-button', {
        source_page: pathname,
        button_location: location ?? null
      });
    }
  }

  displayEnterpriseSupportAlert(): void {
    this.alerts.info(this.intl.t('alerts.plans.upgrade.enterprise_message'), {
      title: this.intl.t('alerts.plans.upgrade.enterprise_title'),
      action: () => {
        window.open(
          'https://help.later.com/hc/en-us/requests/new?&tf_14661951125015=source_product&tf_14233517033111=form_reason_enterprise_question',
          '_blank'
        );
      },
      actionText: this.intl.t('shared_phrases.contact_us'),
      sticky: true,
      preventDuplicates: true
    });
  }

  #getSubscriptionKeyValues(
    subscription: SubscriptionModel | Record<string, never>
  ): SubscriptionKeyValues | Record<string, never> {
    if (!subscription || _isEmpty(subscription) || isNull(subscription)) {
      return {};
    }

    return {
      name: subscription.name,
      amount: subscription.amount,
      frequency: subscription.frequency,
      additionalSocialSets: subscription.additionalSocialSets,
      additionalUsers: subscription.additionalUsers,
      planModifiedTime: subscription.planModifiedTime,
      socialSetsModifiedTime: subscription.socialSetsModifiedTime,
      usersModifiedTime: subscription.usersModifiedTime
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    subscriptions: SubscriptionsService;
  }
}
