export default function ({ intl, isCreator, accountType }) {
  return {
    isCreator,
    'ig-personal-expired': {
      profile: {
        type: intl.t('account.social_accounts.instagram.is_personal'),
        class: 'u--text--danger'
      },
      status: {
        title: intl.t('shared_words.expired'),
        titleClass: 'u--text--danger',
        description: intl.t('account.social_accounts.refresh_profile'),
        descriptionClass: 'u--text--danger'
      },
      action: {
        title: intl.t('shared_words.refresh'),
        isRefreshToken: true,
        class: 'u--text--danger'
      }
    },
    'ig-personal-valid': {
      isValid: true,
      profile: {
        type: intl.t('account.social_accounts.instagram.is_personal'),
        showInstagramLink: true,
        class: 'u--text--black'
      },
      status: {
        title: intl.t('shared_words.connected'),
        titleClass: 'u--text--light',
        description: null
      },
      action: {
        title: intl.t('shared_words.refresh')
      }
    },
    'ig-personal-deprecated': {
      profile: {
        type: intl.t('account.social_accounts.instagram.is_personal'),
        class: 'u--text--danger'
      },
      status: {
        title: intl.t('shared_words.expired'),
        titleClass: 'u--text--danger',
        description: intl.t('account.social_accounts.refresh_profile'),
        descriptionClass: 'u--text--danger'
      },
      action: {
        title: intl.t('shared_words.refresh'),
        class: 'u--text--danger'
      }
    },
    'ig-business-connected-expiring': {
      profile: {
        type: isCreator
          ? intl.t('account.social_accounts.instagram.is_creator')
          : intl.t('account.social_accounts.instagram.is_business'),
        class: 'u--text--black'
      },
      status: {
        title: intl.t('credentials.status.expiring', { profileType: accountType }),
        titleClass: 'u--text--warning',
        description: intl.t('account.social_accounts.refresh_profile'),
        descriptionClass: 'u--text--warning'
      },
      action: {
        title: intl.t('shared_words.refresh'),
        class: 'u--text--danger'
      }
    },
    'ig-business-connected-deprecated': {
      profile: {
        type: isCreator
          ? intl.t('account.social_accounts.instagram.is_creator')
          : intl.t('account.social_accounts.instagram.is_business'),
        class: 'u--text--danger'
      },
      status: {
        title: intl.t('shared_words.refresh'),
        titleClass: 'u--text--danger',
        description: intl.t('account.social_accounts.refresh_profile'),
        descriptionClass: 'u--text--danger'
      },
      action: {
        title: intl.t('shared_words.refresh'),
        class: 'u--text--danger'
      }
    },
    'ig-business-connected-expired': {
      profile: {
        type: isCreator
          ? intl.t('account.social_accounts.instagram.is_creator')
          : intl.t('account.social_accounts.instagram.is_business'),
        class: 'u--text--danger'
      },
      status: {
        title: intl.t('shared_words.expired'),
        titleClass: 'u--text--danger',
        description: intl.t('account.social_accounts.refresh_profile'),
        descriptionClass: 'u--text--danger'
      },
      action: {
        title: intl.t('shared_words.refresh'),
        class: 'u--text--danger'
      }
    },
    'ig-business-connected-valid': {
      isValid: true,
      profile: {
        type: isCreator
          ? intl.t('account.social_accounts.instagram.is_creator')
          : intl.t('account.social_accounts.instagram.is_business'),
        class: 'u--text--black'
      },
      status: {
        title: intl.t('shared_words.connected'),
        titleClass: 'u--text--light',
        description: null
      },
      action: {
        title: intl.t('shared_words.refresh')
      }
    },
    'ig-business-connected-missing-permissions': {
      profile: {
        type: isCreator
          ? intl.t('account.social_accounts.instagram.is_creator')
          : intl.t('account.social_accounts.instagram.is_business'),
        class: 'u--text--black'
      },
      status: {
        title: intl.t('shared_words.connected'),
        titleClass: 'u--text--light',
        description: null
      },
      action: {
        title: intl.t('account.social_accounts.enable_permissions'),
        class: 'u--text--black u--p__lr__sm'
      }
    },
    'ig-business-not-connected': {
      profile: {
        type: isCreator
          ? intl.t('account.social_accounts.instagram.is_creator')
          : intl.t('account.social_accounts.instagram.is_business'),
        class: 'u--text--black'
      },
      status: {
        title: intl.t('shared_words.connected'),
        titleClass: 'u--text--light',
        description: isCreator ? null : intl.t('account.social_accounts.enable_auto_publish'),
        descriptionClass: 'u--text--danger'
      },
      action: {
        title: intl.t('account.social_accounts.enable'),
        isConnectBusiness: true,
        class: 'u--text--danger'
      }
    },
    'facebook-deprecated': (profileType) => ({
      profile: {
        type: profileType,
        class: 'u--text--black'
      },
      status: {
        title: null,
        titleClass: 'u--text--light',
        description: null
      },
      action: {
        text: intl.t('account.social_accounts.facebook_deprecated')
      }
    }),
    'non-ig-expired': (profileType) => ({
      profile: {
        type: profileType,
        class: 'u--text--danger'
      },
      status: {
        title: intl.t('shared_words.expired'),
        titleClass: 'u--text--danger',
        description: intl.t('account.social_accounts.refresh_profile'),
        descriptionClass: 'u--text--danger'
      },
      action: {
        title: intl.t('shared_words.refresh'),
        class: 'u--text--danger'
      }
    }),
    'non-ig-expiring': (profileType) => ({
      profile: {
        type: profileType,
        class: 'u--text--black'
      },
      status: {
        title: intl.t('credentials.status.expiring', { profileType }),
        titleClass: 'u--text--warning',
        description: intl.t('account.social_accounts.refresh_profile'),
        descriptionClass: 'u--text--warning'
      },
      action: {
        title: intl.t('shared_words.refresh'),
        class: 'u--text--danger'
      }
    }),
    'non-ig-valid': (profileType) => ({
      profile: {
        type: profileType,
        class: 'u--text--black'
      },
      status: {
        title: intl.t('shared_words.connected'),
        titleClass: 'u--text--light',
        description: null
      },
      action: {
        title: intl.t('shared_words.refresh')
      }
    }),
    'tiktok-expiring': {
      profile: {
        type: intl.t('shared_words.tiktok'),
        class: 'u--text--black'
      },
      status: {
        title: intl.t('credentials.status.expiring', { profileType: accountType }),
        titleClass: 'u--text--warning',
        description: intl.t('account.social_accounts.refresh_profile'),
        descriptionClass: 'u--text--warning'
      },
      action: {
        title: intl.t('shared_words.refresh'),
        class: 'u--text--danger'
      }
    },
    'tiktok-unverified-valid': {
      profile: {
        type: intl.t('shared_words.tiktok'),
        class: 'u--text--black'
      },
      status: {
        title: intl.t('shared_words.connected'),
        titleClass: 'u--text--light',
        description: undefined,
        showInfo: true
      },
      action: {
        showOnlyEdit: true
      }
    },
    'tiktok-api-refresh': {
      profile: {
        type: intl.t('shared_words.tiktok'),
        class: 'u--text--danger'
      },
      status: {
        title: intl.t('shared_words.expired'),
        titleClass: 'u--text--danger',
        description: intl.t('account.social_accounts.refresh_profile'),
        descriptionClass: 'u--text--danger'
      },
      action: {
        title: intl.t('shared_words.refresh'),
        class: 'u--text--danger'
      }
    },
    'tiktok-api-valid': {
      profile: {
        type: intl.t('shared_words.tiktok'),
        class: 'u--text--black'
      },
      status: {
        title: intl.t('shared_words.connected'),
        titleClass: 'u--text--light',
        description: undefined,
        showInfo: false
      },
      action: {
        title: intl.t('shared_words.refresh')
      }
    }
  };
}
