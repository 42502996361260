import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { collectionAction, memberAction } from 'ember-api-actions';

import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import type {
  EngagementRate,
  FollowersSkew,
  ConnectedPlatform,
  IndustryKey,
  InfluencerSettings,
  CampaignStatus,
  ManageCampaignsFilterOptions
} from 'influencers/types';
import type Campaign from 'later/models/campaign';
import type SocialIdentityModel from 'later/models/social-identity';
import type SocialProfileModel from 'later/models/social-profile';
import type { AddressFields } from 'later/utils/address';

export interface GetCampaignsPayload {
  filter: { industry?: string[] };
  limit: number;
  cursor?: string | null;
}
interface GetCampaignsResponse {
  status: number;
  payload: { campaigns: Campaign[]; paging?: { cursors?: { next?: string } } };
}

interface ManageCampaignsPayload {
  status: ManageCampaignsFilterOptions;
}
interface ManageCampaignsResponse {
  campaign: Campaign;
}

export default class InfluencerModel extends Model {
  @attr('string', { defaultValue: '' }) declare dob: string;
  @attr('string', { defaultValue: '' }) declare readonly name: string;
  @attr('string', { defaultValue: '' }) declare email: string;
  @attr('string', { defaultValue: '' }) declare gender: string;
  @attr() declare address: AddressFields;
  @attr('string', { defaultValue: '' }) declare phoneNumber: string;
  @attr('string', { defaultValue: '' }) declare applicantNote: string;
  @attr('string', { defaultValue: '' }) declare readonly notes: string;
  @attr('string', { defaultValue: '' }) declare firstName: string;
  @attr('string', { defaultValue: '' }) declare lastName: string;
  @attr('string', { defaultValue: '' }) declare readonly followersAge: string;
  @attr('string', { defaultValue: '' }) declare readonly followersCity: string;
  @attr('string', { defaultValue: '' }) declare readonly followersGender: string;
  @attr('string', { defaultValue: '' }) declare readonly followersRegion: string;
  @attr('string', { defaultValue: '' }) declare readonly followersCountry: string;
  @attr('string', { defaultValue: '' }) declare readonly instagramUsername: string;
  @attr('string', { defaultValue: '' }) declare readonly mediaKitReportSlug: string;
  @attr('string', { defaultValue: '' }) declare readonly instagramAvatarUrl: string;
  @attr('string', { defaultValue: '' }) declare readonly engagementRateRange: string;
  @attr('string', { defaultValue: '' }) declare readonly social_identity_id: string;
  @attr('boolean', { defaultValue: false }) declare isShortlisted: boolean; // FE only - set when retrieving influencer shortlist
  @attr('boolean', { defaultValue: false }) declare displayMediaKit: boolean;
  @attr('number', { defaultValue: undefined }) declare optedInAt: number;
  @attr('number', { defaultValue: undefined }) declare searchOptedInAt: number;
  @attr('string', { defaultValue: undefined }) declare readonly industry: IndustryKey;
  @attr('string', { defaultValue: undefined }) declare readonly status: CampaignStatus;
  @attr('string', { defaultValue: undefined }) declare readonly followersRange: FollowersSkew;
  @attr('string', { defaultValue: undefined }) declare readonly engagementRate: EngagementRate;
  @attr() declare ethnicity: string[];
  @attr() declare posts: string[];
  @attr() declare nativePosts: string[];
  @attr() declare connectedPlatforms: ConnectedPlatform[];
  @attr() declare settings: InfluencerSettings;

  @belongsTo('campaign') declare campaign: AsyncBelongsTo<Campaign>;
  @belongsTo('socialIdentity') declare socialIdentity: AsyncBelongsTo<SocialIdentityModel>;
  @hasMany('socialProfile') declare socialProfiles: AsyncHasMany<SocialProfileModel>;

  /**
   * Collection action
   * Called on instance of influencer to retrieve shortlist for given group id

   */
  getShortlisted = collectionAction<{ social_identity_id: string }>({
    type: 'GET',
    path: 'shortlisted'
  });

  getCampaigns = memberAction<GetCampaignsPayload, GetCampaignsResponse>({
    type: 'GET',
    path: 'campaigns'
  });

  manageCampaigns = memberAction<ManageCampaignsPayload, ManageCampaignsResponse>({
    type: 'GET',
    path: 'campaigns/manage'
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    influencer: InfluencerModel;
  }
}
