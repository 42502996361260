import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type SocialIdentityModel from 'later/models/social-identity';
import type AuthService from 'later/services/auth';
import type { Maybe } from 'shared/types';

export type Subnav = 'collect' | 'creators' | 'listening';

export default class AppHeaderService extends Service {
  @service declare auth: AuthService;

  @tracked showFlyout = false;
  @tracked subnav: Subnav = 'collect';
  @tracked refreshHash: Maybe<number>;

  get socialIdentity(): SocialIdentityModel | undefined {
    if (this.auth.currentSocialProfile.get('id')) {
      return this.auth.currentSocialProfile?.get('socialIdentity').content;
    }
    return this.auth.currentGroup.get('socialIdentities').toArray()[0];
  }

  @action
  toggleFlyout(subnav?: Subnav): void {
    this.#generateRefreshHash();

    const hasNewSubnav = subnav && subnav !== this.subnav;
    if (hasNewSubnav) {
      this.subnav = subnav;
    }

    // Note: only toggle flyout when we're not updating an open subnav
    const shouldToggleFlyout = !this.showFlyout || !hasNewSubnav;
    if (shouldToggleFlyout) {
      this.showFlyout = !this.showFlyout;
    }
  }

  @action
  hideFlyout(): void {
    this.showFlyout = false;
  }

  #generateRefreshHash(): void {
    this.refreshHash = Math.floor(Math.random() * (9999 - 1) + 1);
  }
}

declare module '@ember/service' {
  interface Registry {
    'app-header': AppHeaderService;
  }
}
