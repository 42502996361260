/* eslint-disable unicorn/no-null */
import EmberObject from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import { filterTimeSeries } from 'later/utils/array-filters';

import type TableService from 'later/services/analytics/formatters/table';
import type HelpersAnalyticsService from 'later/services/analytics/helpers-analytics';
import type { Moment } from 'moment';
import type { FormattedDynamoFacebookPost, DefaultFacebookPostRow } from 'shared/types/analytics-data/media';

export default class FacebookPostsService extends Service {
  @service('analytics/helpers-analytics') declare helpersAnalytics: HelpersAnalyticsService;
  @service('analytics/formatters/table') declare tableFormatters: TableService;

  /**
   * Key names to be used to form table columns
   */
  names = ['impressions', 'reach', 'clicks', 'likes', 'shares', 'comments', 'engagements', 'reactions'];

  get defaultRow(): DefaultFacebookPostRow {
    return {
      id: null,
      url: null,
      type: null,
      isVideo: false,
      isCarousel: false,
      isDeleted: false,
      createdAt: '',
      createdAtTime: '',
      timestampLongFormat: '',
      createdAtUnix: null,
      caption: null,
      clicks: this.tableFormatters.buildDefaultTableObject(),
      impressions: this.tableFormatters.buildDefaultTableObject(),
      engagements: this.tableFormatters.buildDefaultTableObject(),
      reach: this.tableFormatters.buildDefaultTableObject(),
      likes: this.tableFormatters.buildDefaultTableObject(),
      comments: this.tableFormatters.buildDefaultTableObject(),
      shares: this.tableFormatters.buildDefaultTableObject()
    };
  }

  /**
   * Takes an array of posts, formats each post for the
   * posts table according to the given post key names
   *
   * @returns Array of posts formatted for the posts table
   */
  processPosts(
    posts: FormattedDynamoFacebookPost[] = [],
    startDate: Moment,
    endDate: Moment,
    analyticsStandard: boolean
  ): FormattedDynamoFacebookPost[] {
    if (isEmpty(posts) || !Array.isArray(posts)) {
      return posts;
    }

    const filteredPosts = analyticsStandard
      ? filterTimeSeries(posts, startDate.unix(), endDate.unix(), 'createdTime')
      : posts;

    return filteredPosts
      .map((post) => {
        const isVideo = post.type === 'video_inline';
        const isText = post.type === 'text';
        const values = this.tableFormatters.getValues(this.names, post, filteredPosts);
        const tableRow = this.tableFormatters.buildTableRow(values);

        const imageUrl = isText ? 'assets/images/img--analytics--facebook--text-post.png' : post?.imageUrl;

        const processedPost = Object.assign(
          {},
          {
            id: post.id,
            url: imageUrl || post.fullPicture || null,
            // Used in the CSV export
            fbUrl: isText ? null : post?.imageUrl || post.fullPicture || null,
            createdAt: this.helpersAnalytics.createMomentInTz(post.createdTime).format('MMM D, YYYY') || '-',
            createdAtTime: this.helpersAnalytics.createMomentInTz(post.createdTime).format('LT') || '-',
            timestampLongFormat:
              this.helpersAnalytics.createMomentInTz(post.createdTime).format('MMM D, YYYY hh:mm:ss A') || null,
            createdAtUnix: post.createdTime,
            caption: post.message || null,
            type: post.type,
            isVideo,
            isDeleted: post.isExpired
          },
          ...tableRow
        );

        return EmberObject.create(processedPost);
      })
      .reverse();
  }
}
