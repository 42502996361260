import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import _SessionService from 'ember-simple-auth/services/session';

import redirect from 'shared/utils/redirect';

import type RouterService from '@ember/routing/router-service';
import type GroupModel from 'later/models/group';
import type AdaService from 'later/services/ada';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type FayeService from 'later/services/faye';
import type SegmentService from 'later/services/segment';

export default class SessionService extends _SessionService {
  @service declare router: RouterService;

  @service declare auth: AuthService;
  @service declare ada: AdaService;
  @service declare cache: CacheService;
  @service declare faye: FayeService;
  @service declare segment: SegmentService;

  get group(): GroupModel | undefined {
    return this.auth.lastGroup;
  }

  get groupSlug(): string | undefined {
    return this.group?.slug;
  }

  get isCampaignUrl(): boolean {
    return this.router.currentRoute?.name?.includes('campaigns.campaign');
  }

  get isCampaignFeatureRedirect(): boolean {
    return this.redirectUrl.includes('featurelink/campaigns/');
  }

  get redirectCacheKey(): string {
    return 'session-redirect';
  }

  get redirectUrl(): string {
    return this.cache.retrieve(this.redirectCacheKey) || '';
  }

  get hasRedirectUrl(): boolean {
    return isPresent(this.cache.retrieve(this.redirectCacheKey));
  }

  setRedirectUrl(url = ''): void {
    this.cache.add(this.redirectCacheKey, url, { expiry: this.cache.expiry(3, 'hour'), persist: true });
  }

  clearRedirectUrl(): void {
    this.cache.remove(this.redirectCacheKey);
  }

  /**
   * This ensures that the login is in a proper state
   * before advancing to the route requested post-authentication
   */
  async handleAuthentication(routeAfterAuthentication: string): Promise<void> {
    const route: string | undefined = routeAfterAuthentication;
    await this.auth.setup();
    await this.auth.handleNewAccount();
    this.auth.trackSignIn();

    const groupSlug = this.groupSlug || '';
    if (this.isCampaignUrl && !this.isCampaignFeatureRedirect) {
      this.router.transitionTo(
        'cluster.partnerships.campaign-application.application-form',
        groupSlug,
        this.redirectUrl
      );
      return;
    }

    if (this.isCampaignFeatureRedirect) {
      const { redirectUrl } = this;
      this.clearRedirectUrl();
      redirect(redirectUrl);
    }

    super.handleAuthentication(route);
  }

  /**
   * Handles redirecting after a logout
   */
  handleInvalidation(): void {
    this.faye.unsubscribeUser();
    this.segment.reset();
    this.segment.disableKlaviyoTracking();
    this.ada.reset();

    if (this.redirectUrl) {
      this.auth.clearLocalStorage();
      this.router.transitionTo('user.login');
    } else {
      this.auth.clearLocalStorage();
      this.router.transitionTo('user.login');
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    '-private/session': SessionService;
  }
}
