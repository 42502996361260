import Service, { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { uniqBy } from 'lodash';

import { HttpMethod } from 'later/utils/constants';
import { fetch } from 'later/utils/fetch';

import type { Task } from 'ember-concurrency';
import type ErrorsService from 'later/services/errors';

export enum MavelyLinksOrderByOptions {
  Link = 'link',
  MetaImage = 'meta_image',
  MetaTitle = 'meta_title',
  MetaDescription = 'meta_description',
  MetaSiteName = 'meta_site_name',
  OriginalUrl = 'original_url'
}

interface MavelyBrandDetailsResponse {
  brand_details: MavelyBrandDetails;
}

export interface MavelyBrandDetails {
  commission_type: 'PERCENTAGE' | 'FLAT';
  max_commission: number;
  name: string;
}

export interface MavelyFolder {
  id: string;
  name: string;
  affiliate_links?: MavelyLink[];
}

export interface MavelyLink {
  created_at: string;
  id: string;
  link: string;
  original_url: string;
  meta_url: string;
  meta_title: string;
  meta_image: string;
  meta_description: string;
  brand: string;
}

export interface MavelyFoldersResponse {
  count: number;
  folders: MavelyFolder[];
  has_next_page: boolean;
}

export interface MavelyLinksResponse {
  count: number;
  has_next_page: boolean;
  links: MavelyLink[];
}

type FetchMavelyBrandDetails = Task<MavelyBrandDetailsResponse | undefined, [groupId: string, link: string]>;

type FetchLinksRequestTask = Task<MavelyLinksResponse, [Record<string, never>]>;

type FetchMavelyLinkTask = Task<MavelyLink | undefined, [mavelyProfileId: string, id: string]>;

type FetchMavelyFolderTask = Task<MavelyFolder | undefined, [mavelyProfileId: string, id: string]>;

type FetchMavelyFoldersTask = Task<MavelyFoldersResponse, [mavelyProfileId: string, offset?: number, limit?: number]>;

type FetchMavelyLinksTask = Task<
  MavelyLinksResponse,
  [mavelyProfileId: string, offset?: number, limit?: number, orderBy?: MavelyLinksOrderByOptions, search?: string]
>;

/**
 * This service handles fetching data for all endpoints living under `api/v2/affiliate_links/`
 **/
export default class MavelyAffiliateLinksService extends Service {
  @service declare errors: ErrorsService;

  standardLimitPerRequest = 20;

  /**
   * Folders stored by mavely profile ID
   */
  @tracked profileFolders: Record<string, MavelyFoldersResponse> = {};

  /**
   * Links stored by mavely profile ID
   */
  @tracked profileLinks: Record<string, MavelyLinksResponse> = {};

  convertEligibleLink = task(async (link: string, mavelyProfileId: string) => {
    try {
      const response = await fetch(`/api/v2/affiliate_links/links`, {
        method: HttpMethod.Post,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          profile_id: mavelyProfileId,
          url: link
        })
      });
      return response;
    } catch (error) {
      this.errors.log(`${link} is not a valid brand for Mavely Affiliate links`, error);
    }
  });

  fetchBrandDetails: FetchMavelyBrandDetails = task(async (groupId: string, link: string) => {
    try {
      const response = await fetch(`/api/v2/affiliate_links/brand_details?group_id=${groupId}&link=${link}`);

      return response;
    } catch (error) {
      this.errors.log(`${link} is not a valid brand for Mavely Affiliate links`, error);
    }
  });

  fetchFolder: FetchMavelyFolderTask = task(async (mavelyProfileId, id) => {
    try {
      const existingFolder = this.#getExistingFolder(mavelyProfileId, id);

      if (existingFolder) {
        return existingFolder;
      }

      const urlParams = {
        id,
        profile_id: mavelyProfileId
      };
      const foldersData = await fetch('/api/v2/affiliate_links/folders?' + new URLSearchParams(urlParams).toString());

      return foldersData?.folders[0];
    } catch (error) {
      this.errors.log(`Failed to fetch Mavely folder ${id} for profile: ${mavelyProfileId}`, error);
    }
  });

  fetchFolders: FetchMavelyFoldersTask = task(async (mavelyProfileId, offset, limit) => {
    try {
      const existingFolders = this.#getExistingFolders(mavelyProfileId, offset, limit);

      if (existingFolders) {
        return existingFolders;
      }

      const urlParams = {
        limit,
        offset,
        profile_id: mavelyProfileId
      };
      const foldersData = await fetch('/api/v2/affiliate_links/folders?' + new URLSearchParams(urlParams).toString());

      if (this.profileFolders[mavelyProfileId]?.folders) {
        this.profileFolders[mavelyProfileId] = {
          count: this.profileFolders[mavelyProfileId].count + foldersData.count,
          folders: uniqBy([...this.profileFolders[mavelyProfileId].folders, ...foldersData.folders], 'id'),
          has_next_page: foldersData.has_next_page
        };

        return foldersData;
      }

      this.profileFolders[mavelyProfileId] = foldersData;

      return foldersData;
    } catch (error) {
      this.errors.log(`Failed to fetch Mavely folders for profile: ${mavelyProfileId}`, error);
    }
  });

  /**
   * Fetches a single mavely link for a given mavely profile
   * In the case that the link doesn't exist for the given profile,
   * a MavelyLinksResponse will be returned with an empty links array
   *
   * @param mavelyProfileId The id of the mavely profile to retrieve links for
   * @param id The id of the link to retrieve
   *
   */
  fetchLink: FetchMavelyLinkTask = task(async (mavelyProfileId, id) => {
    const urlParams = {
      profile_id: mavelyProfileId,
      id
    };

    const existingLink = this.profileLinks[mavelyProfileId]?.links.find((link) => link.id === id);
    if (existingLink) {
      return existingLink;
    }

    const mavelyLinksData = await this._fetchLinks.perform(urlParams as unknown as Record<string, never>);
    return mavelyLinksData?.links[0];
  });

  /**
   * Fetches all mavely links for a given mavely profile
   * Updates the locally stored profileLinks record
   * There is one required property, and the rest are optional.
   *
   * @param mavelyProfileId The id of the mavely profile to retrieve links for
   * @param offset The number of links to start fetching links from (used for pagination purposes)
   * @param limit The number of links for BE to send back (used in conjunction with offset for pagination)
   * @param orderBy The link attribute to order returned links by (default order returned is by created_at date where most recent is first)
   * @param search The search query to match links against: filters for partial match on meta_title, meta_description, and original_url
   *
   */
  fetchLinks: FetchMavelyLinksTask = task(async (mavelyProfileId, offset, limit, orderBy, search) => {
    const urlParams = {
      profile_id: mavelyProfileId,
      ...(offset && { offset: offset.toString() }),
      ...(limit && { limit: limit.toString() }),
      ...(orderBy && { order_by: orderBy }),
      ...(search && { search })
    };

    if (!search) {
      const existingLinks = this.#getExistingLinks(mavelyProfileId, offset, limit, orderBy);
      if (existingLinks) {
        return existingLinks;
      }
    }

    const mavelyLinksData = await this._fetchLinks.perform(urlParams);

    if (!search) {
      //Update locally cached data
      const previouslyFetchedLinksForProfile = this.profileLinks[mavelyProfileId]?.links;
      if (previouslyFetchedLinksForProfile) {
        const numberOfPreviouslyFetchedLinks = this.profileLinks[mavelyProfileId]?.count;
        this.profileLinks[mavelyProfileId].has_next_page = mavelyLinksData.has_next_page;
        this.profileLinks[mavelyProfileId].links = [...previouslyFetchedLinksForProfile, ...mavelyLinksData.links];
        this.profileLinks[mavelyProfileId].count = numberOfPreviouslyFetchedLinks + mavelyLinksData.count;
        return this.profileLinks[mavelyProfileId];
      }

      this.profileLinks[mavelyProfileId] = mavelyLinksData;
    }

    return mavelyLinksData;
  });

  private _fetchLinks: FetchLinksRequestTask = task(async (urlParams) => {
    try {
      const response = await fetch('/api/v2/affiliate_links/links?' + new URLSearchParams(urlParams).toString());

      return response;
    } catch (error) {
      if ('id' in urlParams) {
        this.errors.log(`Failed to fetch Mavely link ${urlParams.id} for profile: ${urlParams.profile_id}`, error);
        return;
      }

      this.errors.log(`Failed to fetch Mavely links for profile: ${urlParams.profile_id}`, error);
    }
  });

  #getExistingFolder(mavelyProfileId: string, folderId: string): MavelyFolder | undefined {
    const existingFolder = this.profileFolders[mavelyProfileId]?.folders?.find((folder) => folder.id === folderId);
    return existingFolder?.affiliate_links ? existingFolder : undefined;
  }

  #getExistingFolders(mavelyProfileId: string, offset: number = 0, limit?: number): MavelyFoldersResponse | undefined {
    const existingFolders = this.profileFolders[mavelyProfileId];
    const numberOfFolders = existingFolders?.folders?.length;
    const shouldFetchFolders =
      numberOfFolders && (limit ?? 0) + offset > numberOfFolders && existingFolders?.has_next_page;

    if (existingFolders && !shouldFetchFolders) {
      const formattedLimit = limit ?? numberOfFolders;
      const folders = existingFolders.folders.slice(offset, offset + formattedLimit);
      return {
        ...existingFolders,
        folders,
        has_next_page: offset + formattedLimit < numberOfFolders ? true : existingFolders.has_next_page
      };
    }

    return undefined;
  }

  #getExistingLinks(
    mavelyProfileId: string,
    offset?: number,
    limit?: number,
    orderBy?: string
  ): MavelyLinksResponse | undefined {
    const existingFetchedLinks = this.profileLinks[mavelyProfileId];
    const isCustomOrder = Boolean(orderBy);
    const isFetchingAllExistingLinks = offset === 0;
    if (existingFetchedLinks && !isCustomOrder) {
      if (isFetchingAllExistingLinks) {
        return this.profileLinks[mavelyProfileId];
      }
      const hasEnoughLinksFetched =
        isPresent(limit) && isPresent(offset)
          ? (limit as number) + (offset as number) <= existingFetchedLinks?.links.length
          : true;
      if (hasEnoughLinksFetched) {
        return { ...existingFetchedLinks, links: existingFetchedLinks.links.slice(offset, (limit as number) - 1) };
      }
      return undefined;
    }
    return undefined;
  }
}

declare module '@ember/service' {
  interface Registry {
    'mavely-affiliate-links': MavelyAffiliateLinksService;
  }
}
